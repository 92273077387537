import React from "react"
import IntegrationTemplate from "../../templates/integration"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import IntegrationStorageContent from 'components/content/integration-storage'
import { meta } from '../../configs/storage-providers';
const ContentPage = () => (
  <IntegrationTemplate
    key="sftp"
    name="SFTP"
    shortDescription="Store your backups on your SFTP server or/and replicate it to an external storage."
    website={"https://ssh.com/"}
    links={[{text: 'Documentation', url: '/docs'}]}
    category="Storage"
    logo="sftp-ico.png"
    metaTitle={meta.title.replace('##provider##', 'SFTP')}
    metaDescription={meta.description.replace('##provider##', 'SFTP')}
  >
    <IntegrationStorageContent
      keyRef="sftp"
      service="SFTP"
      provider="SFTP"
    />
  </IntegrationTemplate>
)

export default ContentPage
